import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { MovimientoContext } from "../../Context/MovimientoContext";
import TableMovimientosBody from "./TableBody";
import {
  formatoFechaCorta,
  getComparator,
  stableSort,
} from "../../../../../../Config/reuserFunctions";
import TableMovimientosHead from "./TableHead";
import { Box, Stack, TablePagination, Typography } from "@mui/material";
import ExcelExport from "../../Actions/Export";
import NewReportButton from "../../Actions/CleanReport";
import FilterChips from "../../Details/FilterChips";

export default function ComprasMovsTable() {
  const { query, filter, setFilter, pageConfig, setPageConfig, getMovimientos } = React.useContext(MovimientoContext);
  const { docs, totalDocs } = query?.data;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
    getMovimientos(page, pageConfig.limit)
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig(0, parseInt(event.target.value, 10));
    getMovimientos(0, parseInt(event.target.value, 10))
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
        bgcolor="background.paper"
      >
        <NewReportButton />
        <ExcelExport />
      </Stack>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="subtitle2">
          <b>{`Movimientos ${filter.movimiento} DEL ${formatoFechaCorta(
            filter.fechaInicio
          )} AL ${formatoFechaCorta(filter.fechaFin)}`}</b>
        </Typography>
        <FilterChips filter={filter} setFilter={setFilter} hide="movimiento" />
      </Box>
      <Paper variant="outlined">
        <TableContainer
          sx={{
            transition: "width .3s",
            height: { xs: `calc(100vh - 320px)`, sm: `calc(100vh - 200px)` },
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <TableMovimientosHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <TableMovimientosBody data={renderSorted} />
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
