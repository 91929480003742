import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../../../Components/Inputs/FilterSelect";
import { MovimientoContext } from "../../Context/MovimientoContext";
import FilterDateRange from "../../../../../../Components/Inputs/FilterDateRange";
import FilterStringInput from "../../../../../../Components/Inputs/FilterStringInput";

const headCells = [
  {
    id: "torneo",
    padding: "normal",
    align: "left",
    label: "Torneo",
    sortable: false,
    sx: {
      minWidth: "220px",
    },
    filter: {
      filteringData: "torneos",
      filterName: "torneo",
      filterBy: "nombre",
    },
  },
  {
    id: "competencia",
    padding: "normal",
    align: "left",
    label: "Competencia",
    sortable: false,
    sx: {
      minWidth: "350px",
    },
  },
  {
    id: "usuario",
    padding: "normal",
    align: "left",
    label: "Usuario",
    sortable: false,
    filterString: "username",
    sx: {
      minWidth: "220px",
    },
  },
  {
    id: "email",
    padding: "normal",
    align: "left",
    label: "Email",
    filterString: "useremail",
    sortable: false,
    sx: {
      minWidth: "220px",
    },
  },
  {
    id: "status",
    padding: "checkbox",
    align: "left",
    label: "Status",
    sortable: false,
    sx: null,
    filter: {
      filteringData: "status",
      filterName: "status",
      filterBy: "status",
    },
  },
  {
    id: "fecha",
    padding: "normal",
    align: "left",
    label: "Creada el",
    sortable: true,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "updatedAt",
    padding: "normal",
    align: "left",
    label: "Actualizada el",
    sortable: true,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "balanceEnMovimiento",
    padding: "checkbox",
    align: "left",
    label: "Cantidad",
    sortable: false,
    sx: null,
  },
  {
    id: "balanceAnterior",
    padding: "checkbox",
    align: "left",
    label: "Antes",
    sortable: false,
    sx: null,
  },
  {
    id: "nuevoBalance",
    padding: "checkbox",
    align: "left",
    label: "Despues",
    sortable: false,
    sx: null,
  },
  {
    id: "comentarios",
    padding: "normal",
    align: "left",
    label: "Comentarios",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
];

export default function MovimientosTableHead({ handleSort, order, orderBy }) {
  const {
    catsQuery,
    filter,
    setFilter,
    setFilterCats,
    filterCats,
    getMovimientos,
    FILTER,
    pageConfig
  } = React.useContext(MovimientoContext);

  React.useEffect(() => { 
    getMovimientos(pageConfig.page, pageConfig.limit);
    // eslint-disable-next-line
  }, [FILTER]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogosMov[
                    headCell.filter.filteringData
                  ]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                filterCats={filterCats}
                setFilterCats={setFilterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
            {headCell.filterDate ? (
              <FilterDateRange
                filter={filter}
                setFilter={setFilter}
                filterName={headCell.filterName}
              />
            ) : null}
            {headCell.filterString ? (
              <FilterStringInput
                value={filter}
                setFilter={setFilter}
                filterName={headCell.filterString}
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
