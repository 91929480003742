import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetResultados } from "../graphql/queries";
import { GetAllCatsCats } from "../../../Auditorias/Movimientos/graphql/queries";

export const ResultadoContext = createContext();

const initialFilter = {
  search: "",
  torneo: "",
  equipo: "",
  charro: "",
  suerte: "",
}
const initialCats = {
  torneo: "",
  equipo: "",
  charro: "",
  suerte: "",
}

const ResultadoCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const [FILTERCATS] = useDebounce(filterCats, 500);
  const { page, limit } = pageConfig;

  const resultQuery = useQuery(GetResultados, {
    variables: {
      filter: FILTER,
      page: page + 1,
      limit,
    },
    //fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });
  const catsQuery = useQuery(GetAllCatsCats, {
    variables: {filter: FILTERCATS},
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  return (
    <ResultadoContext.Provider
      value={{
        filter,
        setFilter,
        setFilterCats,
        filterCats,
        initialFilter,
        resultQuery,
        catsQuery,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </ResultadoContext.Provider>
  );
};

export default ResultadoCtxProvider;
