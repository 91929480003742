import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetCompetencias } from "../graphql/queries";
import { GetAllCatsCats } from "../../../Auditorias/Movimientos/graphql/queries";

export const CompetenciaContext = createContext();

const initialFilter = {
  search: "",
  torneo: "",
  charreada: "",
  equipo: "",
  charro: "",
  suerte: "",
  status: "",
};

const initialCats = {
  torneo: "",
  charreada: "",
  equipo: "",
  charro: "",
  suerte: "",
};

const CompetenciaCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const [FILTERCATS] = useDebounce(filterCats, 500);
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [compDetail, setCompDetail] = React.useState(null);
  const [refetchApuestas, setRefetchApuestas] = React.useState(null);
  const [showCalc, setShowCalc] = React.useState(
    (JSON.parse(localStorage.getItem("ShowCalc")))
  );
  const { page, limit } = pageConfig;

  const compQuery = useQuery(GetCompetencias, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });
  const catsQuery = useQuery(GetAllCatsCats, {
    variables: { filter: FILTERCATS, getAll: true },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  return (
    <CompetenciaContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        setFilterCats,
        filterCats,
        compQuery,
        catsQuery,
        pageConfig,
        setPageConfig,
        itemsSelected,
        setItemsSelected,
        indeterminated,
        setIndeterminated,
        compDetail,
        setCompDetail,
        showCalc,
        setShowCalc,
        refetchApuestas,
        setRefetchApuestas
      }}
    >
      {children}
    </CompetenciaContext.Provider>
  );
};

export default CompetenciaCtxProvider;
